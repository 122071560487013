import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { motion } from "framer-motion";
const Isilanlari = () => {

   const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.1,
        ease: "easeInOut",
      },
    },
    exit: {
      transition: { duration: 0, delay: 0 },
    },
  };
  
   const itemVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
    exit: { opacity: 0, y: 20, transition: { duration: 0, delay: 0 } },
  };
  

  const [buttonMessage, setButtonMessage] = useState('GÖNDER'); // Button message state

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    firstName: Yup.string().required('İsim gereklidir'),
    lastName: Yup.string().required('Soy isim gereklidir'),
    phoneNumber: Yup.string()
      .required('Telefon numarası gereklidir')
      .matches(/^[0-9]+$/, 'Sadece rakam içermelidir')
      .min(10, 'En az 10 karakter olmalı'),
    email: Yup.string().email('Geçersiz email adresi').required('Email gereklidir'),
    position: Yup.string().required('Başvurulan pozisyon gereklidir'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm, validateForm }) => {
    try {
      // Validate the form before submitting
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setSubmitting(false);
        return; // If there are validation errors, do not proceed
      }
  
      const response = await fetch('https://inceoglun.com.tr/inceoglun-contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
  
      const result = await response.json();
      if (result.status === 'success') {
        setButtonMessage('Gönderildi!');
        resetForm(); // Clear input fields
        setTimeout(() => window.location.reload(), 2000); // Reload the page after 2 seconds
      } else {
        setButtonMessage('Hata oluştu!');
      }
    } catch (error) {
      console.error('Gönderim hatası:', error);
      setButtonMessage('Hata oluştu!');
    } finally {
      setTimeout(() => setButtonMessage('GÖNDER'), 2000); // Reset button message
      setSubmitting(false);
    }
  };

  return (
    <motion.div
      className="min-h-screen flex flex-col items-center w-full px-8 md:px-16 lg:px-36 pt-20 md:pt-28"
      style={{ background: 'radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)' }}
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      exit="exit"
    >
      <div className="h-full w-full max-w-screen-md flex flex-col justify-center space-y-5 pt-20">
        <motion.h5 variants={itemVariants} className="text-4xl font-bold text-white mb-10">İŞ BAŞVURUSU</motion.h5>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            position: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-5">
              <div className="flex items-center space-x-5">
                <motion.h6 variants={itemVariants}   className="w-52 text-white">İsim</motion.h6>
                <motion.div variants={itemVariants} className="w-full">
                  <Field
                    name="firstName"
                    placeholder="İsminizi giriniz"
                    className="input-field py-2 px-4 w-full border border-inceoglunYellow1"
                  />
                  <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm" />
                </motion.div>
              </div>
              <div className="flex items-center space-x-5">
                <motion.h6 variants={itemVariants} className="w-52 text-white">Soy İsim</motion.h6>
                <motion.div variants={itemVariants} className="w-full">
                  <Field
                    name="lastName"
                    placeholder="Soy isminizi giriniz"
                    className="input-field py-2 px-4 w-full border border-inceoglunYellow1"
                  />
                  <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm" />
                </motion.div>
              </div>
              <div className="flex items-center space-x-5">
                <motion.h6 variants={itemVariants} className="w-52 text-white">Telefon Numarası</motion.h6>
                <motion.div variants={itemVariants} className="w-full">
                  <Field
                    name="phoneNumber"
                    placeholder="Telefon numaranızı giriniz"
                    className="input-field py-2 px-4 w-full border border-inceoglunYellow1"
                  />
                  <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm" />
                </motion.div>
              </div>
              <div className="flex items-center space-x-5">
                <motion.h6 variants={itemVariants} className="w-52 text-white">E-Mail Adresi</motion.h6>
                <motion.div variants={itemVariants} className="w-full">
                  <Field
                    name="email"
                    placeholder="E-Mail adresinizi giriniz"
                    className="input-field py-2 px-4 w-full border border-inceoglunYellow1"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                </motion.div>
              </div>
              <div className="flex items-center space-x-5">
                <motion.h6 variants={itemVariants} className="w-52 text-white">Başvurulan Pozisyon</motion.h6>
                <motion.div variants={itemVariants} className="w-full">
                  <Field
                    name="position"
                    placeholder="Pozisyonu giriniz"
                    className="input-field py-2 px-4 w-full border border-inceoglunYellow1"
                  />
                  <ErrorMessage name="position" component="div" className="text-red-500 text-sm" />
                </motion.div>
              </div>
              <motion.div variants={itemVariants}>
              <div   className="py-2 w-40 inline-flex justify-center items-center border border-white hover:scale-105 transition-all duration-500 cursor-pointer !mt-14">
                <button
                  type="submit"
                  className="text-[10px] font-bold text-white !w-full !h-full !block"
                  disabled={isSubmitting}
                 
                >
                  {isSubmitting ? 'GÖNDERİLİYOR...' : buttonMessage}
                </button>
              </div>
              </motion.div>
            </Form>
          )}
        </Formik>
      </div>
        <motion.div variants={itemVariants}>
      <a
        href="https://www.eleman.net/firma/inceoglu-makina-ticaret-ve-sanayi-a-s-f1771165"
        target="_blank"
        rel="noopener noreferrer"
        className="h-full w-full max-w-screen-md flex flex-col justify-center space-y-5 mt-20 border-inceoglunYellow1 border px-5 py-5 mb-20 cursor-pointer hover:scale-105 transition-all duration-300"
      >
        <div className="flex justify-between h-20 ">
          <div className="flex items-center space-x-5">
            <img src="/images/logos/logo.png" className="w-16 md:w-44" alt="Firma Logosu" />
            <div className="text-white">Açık iş ilanlarına göz atmak için tıklayınız.</div>
          </div>
          <div>
            <img src="/images/kariyer-arrow.svg" className="h-full" alt="İş İlanları" />
          </div>
        </div>
      </a>
      </motion.div>
    </motion.div>
  );
};

export default Isilanlari;
