import React, { useRef } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'

import { delay, motion } from "framer-motion"

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';



// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const Tarihce = () => {
  const scrollRef = useRef(null)
  const section1 = useRef()
  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 1,
      },
    },
    hidden: { opacity: 0 },

  }


  const list2 = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 1,
      },
    },
    hidden: { opacity: 0 },

  }



  const list3 = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 1,
      },
    },
    hidden: { opacity: 0 },

  }


  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 } }
  const itemTextt = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 } }
  const itemLine = { visible: { width: '100%', transition: { duration: 6 } }, hidden: { width: '0%' } }
  const itemText2 = { visible: { translateX: '150%', opacity: [0, 0, 1, 1, 0], transition: { duration: 1.5 } }, hidden: { translateX: 0, opacity: 0 } }


  const itemText3 = { visible: { translateY: '115%', opacity: [0, 0, 1, 1, 0], transition: { duration: 1.5 } }, hidden: { translateX: 0, opacity: 0 } }

  useGSAP(
    () => {

      gsap.from(".history", {


        duration: 2,
        opacity: 0

      });

      gsap.from(".history-content-container", {

        x: 50,
        duration: 2,
        opacity: 0

      });


    })


  return (
    <div className='relative  history' style={{ background: "rgb(69,40,0)", background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }} >

      <div className='h-auto lg:h-screen pt-[7rem] md:pt-28 relative overflow-hidden '>
        <img
          src="/images/general/bina.jpg"
          alt="logo"
          className="  w-full h-full object-center object-cover  absolute"
        />


        <div className=' w-full  z-20 relative px-8 md:px-16 lg:px-36 py-10 history-content-container  '>
          <div className='w-full lg:w-[40%] h-full bg-[#757070] bg-opacity-80 p-10 space-y-5 font-light tracking-wides text-white'>
            <h5 className='font-extrabold text-4xl '>Tarihçe</h5>
            <p className=''>İnceoğlu Un Fabrikası’nın kuruluşu 1944 yılına dayanır. Hacı Ahmet İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif Şirketi ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde ve 25 t/gün kapasiteli olarak kurulmuş ve bu fabrikayla sektörde uzun yıllar faaliyetini sürdürmüştür.</p>
            <p className=''>1966 yılından itibaren İnceoğlu Makine Ticaret ve Sanayi A.Ş olarak Denizli Un Fabrikaları Kollektif Şirketi’nin ortağı olmuştur. 1976 senesinde İzmir Karayolu üzerindeki 150 t/gün kapasiteli  yeni bir fabrika kurulmuş ve modern üretim geçmişten gelen deneyimle harmanlanmıştır. İnceoğlu Un Fabrikası sağlam bir temele oturmuş sektörel faaliyetlerini, teknik açıdan da devamlı geliştirerek, kalite çizgisini hep zirvede tutmayı amaçlamıştır.</p>
            <p className=''>2007 yılına gelindiğinde, yeniden yapılanma sonucu, müşterilerine  üretimini kendi isminden gelen “İNCEOĞLUN” markasıyla sunmaya başladı. Revizyonlarla 300t/gün öğütme kapasitesine ulaşan İnceoğlu Un Fabrikası, bugün  modern gıda işletmeciliğinin uluslararası standartlarında üretimini gerçekleştirmektedir. </p>
          </div>
        </div>


      </div>

      <div className='w-full py-24 text-4xl font-bold  text-white flex items-center justify-center text-center px-10 montSerrat tracking-wider mt-32'>
Tohumları 1944 yılında bir değirmende atılan markamızla
<br className='hidden md:block'/>
80 yıldır her gün aynı özen ve disiplinle hizmet veriyoruz…</div>



      <motion.div ref={scrollRef} initial="hidden" whileInView="visible" className=' px-8 md:px-16 lg:px-36 hidden md:block'   >
        <div className='relative  h-72'>
          <motion.div variants={list} className='grid grid-cols-5 w-full  mb-20 h-72'>
            <motion.div variants={itemText} className='flex justify-center'>
              <img src='/images/history/b1.png' className=' h-72'/>
            </motion.div>
            <motion.div variants={itemText} className=' flex justify-center'>
              <img src='/images/history/b2.png'   className=' h-72'/>
            </motion.div>
            <motion.div variants={itemText} className=' flex justify-center'>
              <img src='/images/history/b3.png'  className=' h-72'/>
            </motion.div>
            <motion.div variants={itemText} className='flex justify-center '>
              <img src='/images/history/b4.png'  className=' h-72'/>
            </motion.div>
            <motion.div variants={itemText} className='flex justify-center '>
              <img src='/images/history/b5.png' className=' h-72' />
            </motion.div>
          </motion.div>

          <motion.div variants={list2} className='grid grid-cols-5 w-full  mb-20 absolute top-0 left-0  h-72'>
            <motion.div variants={itemText2} className='  h-72'>
              <img src='/images/history/b1.png' className=' h-72' />
            </motion.div>
            <motion.div variants={itemText2} className=' '>
              <img src='/images/history/b2.png' className=' h-72' />
            </motion.div>
            <motion.div variants={itemText2} className=''>
              <img src='/images/history/b3.png'  className=' h-72'/>
            </motion.div>
            <motion.div variants={itemText2} className=' '>
              <img src='/images/history/b4.png'  className=' h-72'/>
            </motion.div>

          </motion.div>
        </div>



        <div className=' w-full   items-center mb-10'>


          <motion.div variants={list} className='grid grid-cols-5  gap-10'>
            <motion.div variants={itemTextt} className=' w-full  flex justify-center'>
            <div className=' w-full '>
                <img src='/images/history/a1.jpg'/>
              </div>
            </motion.div>
            <motion.div variants={itemTextt} className=' w-full flex justify-center'>
            <div className=' w-full '>
                <img src='/images/history/a2.jpg'/>
              </div>
            </motion.div>
            <motion.div variants={itemTextt} className=' w-full flex justify-center '>
            <div className=' w-full '>
                <img src='/images/history/a3.jpg'/>
              </div>
            </motion.div>
            <motion.div variants={itemTextt} className=' w-full flex justify-center '>
            <div className=' w-full '>
                <img src='/images/history/a4.jpg'/>
              </div>
            </motion.div>
            <motion.div variants={itemTextt} className='w-full flex justify-center'>
            <div className=' w-full '>
                <img src='/images/history/a5.jpg'/>
              </div>
            </motion.div>
          </motion.div>
        </div>





        <div className=' w-full   items-center mb-10'>
          <motion.div variants={list} className='w-full relative flex   bg-red-300'>

            <motion.div variants={itemLine} className=' absolute h-px top-2.5 bg-white  opacity-30'></motion.div>
          </motion.div>

          <motion.div variants={list} className='grid grid-cols-5 '>
            <motion.div variants={itemTextt} className=' w-full  flex justify-center'>
              <div className='h-5 w-5 bg-white rounded-full'></div>
            </motion.div>
            <motion.div variants={itemTextt} className=' w-full flex justify-center'>
              <div className='h-5 w-5 bg-white rounded-full'></div>
            </motion.div>
            <motion.div variants={itemTextt} className=' w-full flex justify-center '>
              <div className='h-5 w-5 bg-white rounded-full'></div>
            </motion.div>
            <motion.div variants={itemTextt} className=' w-full flex justify-center '>
              <div className='h-5 w-5 bg-white rounded-full'></div>
            </motion.div>
            <motion.div variants={itemTextt} className='w-full flex justify-center'>
              <div className='h-5 w-5 bg-white rounded-full'></div>
            </motion.div>
          </motion.div>
        </div>






        <motion.div variants={list} className='grid grid-cols-5 w-full  text-white gap-10 pb-20 '>
          <motion.div variants={itemTextt} className='flex flex-col '>
            <h5 className='text-4xl font-bold text-center'>1944</h5>
            <p>Hacı Ahmet İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif Şirketi ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde kurulmuştur. 25 ton/gün kapasiteli bu değirmen sektörde uzun yıllar faaliyetlerini sürdürmüştür.</p>
          </motion.div>
          <motion.div variants={itemTextt} className=' flex flex-col'>
            <h5 className='text-4xl font-bold text-center'>1974</h5>
            <p>İzmir Karayolu üzerinde 150 ton/gün kapasiteyle yeni bir fabrika kurulmuştur.
Geçmişten gelen deneyim, yeni fabrikanın temellerini oluşturmuştur.
</p>

          </motion.div>
          <motion.div variants={itemTextt} className='flex flex-col'>
            <h5 className='text-4xl font-bold text-center'>2007</h5>
            <p>Yeniden yapılanma sürecinin bir parçası olarak ‘’İNCEOĞLUN’’ markası altında hizmet sunulmaya başlanmıştır.
            </p>

          </motion.div>
          <motion.div variants={itemTextt} className='flex flex-col '>
            <h5 className='text-4xl font-bold text-center'>2009</h5>
            <p>Alman değirmen mühendisi Karl Heinz Hawallek danışmanlığında revizyon sürecine girilmiştir. Öğütme kapasitesi bu kapsamda 300 ton/gün’e ulaşmıştır.</p>

          </motion.div>
          <motion.div variants={itemTextt} className='flex flex-col '>
            <h5 className='text-4xl font-bold text-center'>2019</h5>
            <p>6.000 ton kapasiteli otomatik havalandırma sistemine sahip çelik buğday siloları kurulmuştur. Toplam depolama kapasitesi 10.000 ton’a ulaşmıştır.</p>

          </motion.div>
        </motion.div>

      </motion.div>




















      <motion.div ref={scrollRef} initial="hidden" whileInView="visible" className=' grid-cols-3  grid md:hidden'>


        <div className='relative col-span-1'>
          <motion.div variants={list} className='grid grid-cols-1 w-full  mb-20 '>
            <motion.div variants={itemText} className=''>
              <img src='/images/history/b1.png' />
            </motion.div>
            <motion.div variants={itemText} className=' '>
              <img src='/images/history/b2.png' />
            </motion.div>
            <motion.div variants={itemText} className=''>
              <img src='/images/history/b3.png' />
            </motion.div>
            <motion.div variants={itemText} className=' '>
              <img src='/images/history/b4.png' />
            </motion.div>
            <motion.div variants={itemText} className=' '>
              <img src='/images/history/b5.png' />
            </motion.div>
          </motion.div>

          <motion.div variants={list3} className='grid grid-cols-1 w-full  mb-20 absolute top-0 left-0 '>
            <motion.div variants={itemText3} className=' '>
              <img src='/images/history/b1.png' />
            </motion.div>
            <motion.div variants={itemText3} className=' '>
              <img src='/images/history/b2.png' />
            </motion.div>
            <motion.div variants={itemText3} className=''>
              <img src='/images/history/b3.png' />
            </motion.div>
            <motion.div variants={itemText3} className=' '>
              <img src='/images/history/b4.png' />
            </motion.div>

          </motion.div>
        </div>



        <motion.div variants={list} className='grid col-span-2 w-full  text-white gap-10 mb-20'>
          <motion.div variants={itemTextt} className='flex flex-col justify-center'>
            <motion.div variants={itemTextt} className=' w-full flex justify-center mb-3'>
              <div className=' w-full '>
                <img src='/images/history/a1.jpg'/>
              </div>
            </motion.div>
            <h5 className='text-4xl font-bold text-center'>1944</h5>
            <p>Markamızla tanışan profesyonellerin tüm taleplerine karşılık verebilmek ve sürdürülebilir bir memnuniyet zinciri kurabilmek.</p>
          </motion.div>
          <motion.div variants={itemTextt} className=' flex flex-col justify-center space-y-2'>
            <motion.div variants={itemTextt} className=' w-full flex justify-center'>
            <div className=' w-full '>
                <img src='/images/history/a2.jpg'/>
              </div>
            </motion.div>
            <h5 className='text-4xl font-bold text-center'>1960</h5>
            <p>Markamızla tanışan profesyonellerin tüm taleplerine karşılık verebilmek ve sürdürülebilir bir memnuniyet zinciri kurabilmek.</p>

          </motion.div>
          <motion.div variants={itemTextt} className='flex flex-col justify-center space-y-2'>
            <motion.div variants={itemTextt} className=' w-full flex justify-center'>
            <div className=' w-full '>
                <img src='/images/history/a3.jpg'/>
              </div>
            </motion.div>
            <h5 className='text-4xl font-bold text-center'>1960</h5>
            <p>Markamızla tanışan profesyonellerin tüm taleplerine karşılık verebilmek ve sürdürülebilir bir memnuniyet zinciri kurabilmek.</p>

          </motion.div>
          <motion.div variants={itemTextt} className='flex flex-col justify-center space-y-2'>
            <motion.div variants={itemTextt} className=' w-full flex justify-center'>
            <div className=' w-full '>
                <img src='/images/history/a4.jpg'/>
              </div>
            </motion.div>
            <h5 className='text-4xl font-bold text-center'>2000</h5>
            <p>Markamızla tanışan profesyonellerin tüm taleplerine karşılık verebilmek ve sürdürülebilir bir memnuniyet zinciri kurabilmek.</p>

          </motion.div>
          <motion.div variants={itemTextt} className='flex flex-col justify-center space-y-2'>
            <motion.div variants={itemTextt} className=' w-full flex justify-center'>
            <div className=' w-full '>
                <img src='/images/history/a5.jpg'/>
              </div>
            </motion.div>
            <h5 className='text-4xl font-bold text-center'>2024</h5>
            <p>Markamızla tanışan profesyonellerin tüm taleplerine karşılık verebilmek ve sürdürülebilir bir memnuniyet zinciri kurabilmek.</p>

          </motion.div>
        </motion.div>




      </motion.div>








    </div>
  )
}

export default Tarihce