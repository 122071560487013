import React from 'react'
import { motion } from "framer-motion"


const certificateData = [
    {
        logo: 'LOGO',
        text: ' KALİTE POLİTİKASI',
        link: '/pdf/Inceoglu-Kalite-Politikasi.pdf',
        image: '/images/politikalar/kalite-politika.jpg'
    },
    {
        logo: 'LOGO',
        text: ' GIDA GÜVENLİĞİ POLİTİKASI',
        link: '/pdf/Inceoglu-Gida-Guvenligi-Politikasi.pdf',
        image: '/images/politikalar/gida-guvenligi-politika.jpg'
    },
    {
        logo: 'LOGO',
        text: ' CAM, AHŞAP ve METAL BAKIM POLİTİKASI',
        link: '/pdf/Inceoglu-Cam-Ahsap-ve-Metal-Bakim-Politikasi.pdf',
        image: '/images/politikalar/cam-ahsap-metal-bakim-politika.jpg'
    },
    {
      logo: 'LOGO',
      text: ' İNSAN KAYNAKLARI POLİTİKASI',
      link: '/pdf/incegolu-insan-kaynaklari-politikasi.pdf',
      image: '/images/politikalar/cam-ahsap-metal-bakim-politika.jpg'
  },




  {
    logo: 'LOGO',
    text: ' METAL POLİTİKASI',
    link: '/pdf/inceoglun-metal-politikasi.pdf',
    image: '/images/politikalar/cam-ahsap-metal-bakim-politika.jpg'
},

{
  logo: 'LOGO',
  text: ' ÜRETİM POLİTİKASI',
  link: '/pdf/inceoglun-uretim-politikasi.pdf',
  image: '/images/politikalar/cam-ahsap-metal-bakim-politika.jpg'
},


{
  logo: 'LOGO',
  text: ' SABİT KIYMET VE MALZEME POLİTİKASI',
  link: '/pdf/sabit-kiymet-vemalzeme-politikasi.pdf',
  image: '/images/politikalar/cam-ahsap-metal-bakim-politika.jpg'
},


{
  logo: 'LOGO',
  text: ' HELAL POLİTİKASI',
  link: '/pdf/inceoglun-helal.pdf',
  image: '/images/politikalar/helal.jpg'
},










]

const YönetimPolitikalarimiz = () => {


    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2,
            },
        },
        hidden: { opacity: 0 },

    }

    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }


      
      const itemAnim1 = { visible: { translateX: 0,opacity: 1, transition: { duration: 1 } }, hidden: {translateX: -30, opacity: 0 }, }
      const itemAnim2 = { visible: { translateX: 0,opacity: 1, transition: { duration: 1 } }, hidden: {translateX: 30, opacity: 0 }, }


    return (
        <motion.div initial="hidden" whileInView="visible" variants={list} className='min-h-screen   w-full py-20 md:py-8  ' style={{ background: "rgb(69,40,0)", background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }}>
            <div className='h-full w-full flex flex-col justify-center space-y-20 pt-20'>
                {/* <motion.div variants={itemText} className='text-white space-y-4 '>

                    <h5 className='text-4xl font-bold '>Yönetim Politikalarımız</h5>
                    <div className='space-y-2 text-slate-300'>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.Cum sociis natoque penatibus et magnis dis parturient.</p>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.Cum sociis natoque penatibus et magnis dis parturient.
                        </p>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.Cum sociis natoque penatibus et magnis dis parturient.</p>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.Cum sociis natoque penatibus et magnis dis parturient.</p>
                    </div>
                </motion.div> */}




                <div className='overflow-x-hidden'>
<div>
      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-1 lg:grid-cols-2'>
        <div className=' w-full flex flex-col justify-center lg:pl-[10%] lg:pr-[10%] lg:py-[10%] p-[10%]'>
          <motion.h5  variants={itemAnim1} className='text-4xl font-bold    mb-8 text-white '> KALİTE POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim1} className='text-customGray leading-6 text-white space-y-2'>
          <p >Müşterilerimizi memnun, çalışanlarımızı mutlu etmekir.</p>
          <p >Aldığımız buğdayları modern ve sağlıklı koşullarda işleyerek, kaliteli ürünlere dönüştürmek ve
uygun koşullarda müşterilerimize sunmak,
</p>
<p>Bütün çalışmalarımızı tasarruf bilinci içinde ve serbest piyasa koşullarında, dürüstlük, eşitlik ve</p>
<p></p>
          </motion.div>
        </div>
        <motion.div variants={itemAnim2} className=' bg-red-400  '>
          <img  className='h-full w-full object-cover' src={'/images/yonetim-politikalari/kalite.jpg'} />
        </motion.div>
      </motion.div>






      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid  grid-cols-1 lg:grid-cols-2'>
        <motion.div variants={itemAnim1} className=' bg-orange-300 w-full order-2 lg:order-1'>
          <img  className='h-full w-full object-cover aspect-square' src={'/images/yonetim-politikalari/gida.jpg'} />
        </motion.div>
        <div className='  w-full flex flex-col justify-center lg:pr-[10%] lg:pl-[10%] lg:py-[10%] p-[10%] order-1 lg:order-2'>
          <motion.h5 variants={itemAnim2} className='text-4xl font-bold  mb-8 text-white '> GIDA GÜVENLİĞİ POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim2} className='text-customGray leading-6 text-white space-y-2'>
          <p>Firmamız kaliteli, gıda güvenliğine uygun ürün ve hizmet sunarak müşterilerimizin sağlığını korumak ve daima müşteri memnuniyeti prensibini temel ilke alarak benimsemiştir.</p>
<p>Bu amaca ulaşmak için; uygun olmayan ve zararlı olma potansiyeli taşıyan gıdaların satışa çıkarılmasını önleyip, gıda otantisitesini benimseyerek kasıtlı olarak yanıltıcı bilgiler verilmemesini taahhüt etmektedir.</p>
<p>Takım ruhu ile çalışarak tüm çalışanlarımızı bu doğrultuda yönlendirmek, tedarikçilerimiz ve müşterilerimiz ile birlikte kendimizi geliştirmek, yürürlükteki tüm gıda ile ilgili mevzuat, düzenleme ve standartlara uymak, tesisimizde Gıda Güvenliği ve Kalite Kültürünü sürekli iyileştirme anlayışı doğrultusunda “Güvenli ve Kaliteli Gıdaya” ulaşmak iş ilkesi olarak kabul edilmiştir.</p>

          </motion.div>
        </div>
      </motion.div>







      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <div className='  w-full flex flex-col justify-center lg:pl-[10%] lg:pr-[10%] lg:py-[10%]  p-[10%]'>
          <motion.h5 variants={itemAnim1} className='text-4xl font-bold  mb-8  text-white '> CAM, AHŞAP ve METAL BAKIM POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim1} className='text-customGray leading-6 text-white space-y-2'>
          <p>Çalışma alanlarında cam kullanımından kaçınılır. Filmli cam, bazı önemli yerlerde paslanmaz çelik ve plastik kullanılır.</p>
<p>Lambalar muhafazalı veya kırıldığında dağılmayacak malzemeden seçilir ve kullanılır.</p>
<p>Cam kırılması durumunda, Gıda Güvenliği Ekip Lideri, cam bulaşması olup olmadığını araştırır ve bu durumu bir tutanak ile kayıt altına alır.</p>
<p>Çalışma alanlarında, gıdaya temas eden yüzeyler; temizlenebilir, gıdaya uygun kaplama veya fırınlı ahşaptır.</p>
<p>Bakım faaliyetleri için gıda imalatı yapılan alanlara giriş ve çıkışta, kullanılan malzeme ve ekipmanlar sayısal ve niteliksel olarak kontrol edilir.</p>

          </motion.div>
        </div>
        <motion.div variants={itemAnim2} className=' bg-red-400'>
          <img  className='h-full w-full object-cover aspect-square' src={'/images/yonetim-politikalari/camm.jpg'} />
        </motion.div>
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <motion.div variants={itemAnim1} className=' bg-orange-300 w-full order-2 lg:order-1'>
          <img  className='h-full w-full object-cover aspect-square' src={'/images/yonetim-politikalari/insan.jpg'} />
        </motion.div>
        <div className='  w-full flex flex-col justify-center lg:pr-[10%] lg:pl-[10%] lg:py-[10%]  p-[10%] order-1 lg:order-2'>
          <motion.h5 variants={itemAnim2} className='text-4xl font-bold  mb-8 text-white '>İNSAN KAYNAKLARI POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim2} className='text-customGray leading-6 text-white space-y-2'>
          <p>İş, bir değer yaratmak için çalışmaktır. Bunu her gün aynı özenle bir takım halinde yapmak ve çözümün ortağı olma motivasyonunu sağlamak en büyük ilkemizdir.</p>
<p>Proaktif, işini tutkuyla yapan, uzun vadeli hedeflere ulaşmak için kurum kültürünü esas alarak bağlılıkla çalışan ekip arkadaşlarımızın olduğu bir ekosistemin sürekliliğini sağlamak en büyük gayemizdir.</p>
<p>Hedefimiz, deneyime değer veren, bununla birlikte yeniliklere ve dinamizme güvenen; yetkinlikler yaratmaya çalışan, kapsamları belirlenmiş insiyatif alanlarına sahip; sorumluluk ve görev bilinci yüksek, öz yönelimci takım oyuncularını destekleyen bir iş ekosistemini sürdürülebilir kılmaktır.</p>

          </motion.div>
        </div>
      </motion.div>





      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <div className='  w-full flex flex-col justify-center lg:pl-[10%] lg:pr-[10%] lg:py-[10%]  p-[10%]'>
          <motion.h5 variants={itemAnim1} className='text-4xl font-bold  mb-8  text-white  '>METAL POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim1} className='text-customGray leading-6 text-white space-y-2'>
          <p>İnceoğlu Makina Ticaret ve Sanayi A.Ş. olarak gıda güvenliğini etkileyen fiziksel tehlikeler dahilinde metal kaynaklı yabancı madde kontrollerimiz bulunmaktadır.</p>
<p>Bu kapsamda hammadde alımından ürünlerin sevkiyatına kadar olan süreçte, çeşitli aşamalarda ve proseslerde metal dedektör, mıknatıs çubuk ve füze mıknatıslarımız bulunmaktadır. Mıknatıslarımızın kontrolleri ve temizlikleri Gıda Güvenliği Planımız dahilinde periyodik olarak yapılmaktadır.</p>
<p>Bununla birlikte, bütün girdiler bu kapsamda kontrolden geçmektedir.</p>

          </motion.div>
        </div>
        <motion.div variants={itemAnim2} className=' bg-red-400'>
          <img  className='h-full w-full object-cover aspect-square' src={'/images/yonetim-politikalari/metal.jpg'} />
        </motion.div>
      </motion.div>




      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <motion.div variants={itemAnim1} className=' bg-orange-300 w-full order-2 lg:order-1'>
          <img  className='h-full w-full object-cover aspect-square' src={'/images/yonetim-politikalari/ik.jpg'} />
        </motion.div>
        <div className='  w-full flex flex-col justify-center lg:pr-[10%] lg:pl-[10%] lg:py-[10%]  p-[10%] order-1 lg:order-2'>
          <motion.h5 variants={itemAnim2} className='text-4xl font-bold  mb-8 text-white '>ÜRETİM POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim2} className='text-customGray leading-6 text-white space-y-2'>
          <p>Firma olarak her üretim sürecini titizlik ve disiplin içinde gerçekleştiriyoruz. Sahip olduğumuz köklü üretim tecrübesini, teknoloji altyapımız ve nitelikli çalışma arkadaşlarımızla beraber harmanlıyoruz. Kaliteye, sürdürülebilirliğe, gıda güvenliğine, iş sağlığı & güvenliğine, çevre yönetimine ve verimliliğe önem veriyor, üretim süreçlerimizi bu hususlar kapsamında yönetiyoruz.</p>
<p>Kurum ve gıda güvenliği kültürüne önem veriyoruz ve bu hususta kurumsal hafızamızın güçlü bir özelliğimiz olduğu bilincinde hareket ediyoruz.</p>
<p>Üretimde sürekliliği sağlamak adına bakım programlarımızı organize ediyor, planlı bakım çalışmalarıyla destekliyoruz. Bakımlarda reaktif olmaktan ziyade proaktif olmaya çalışıyoruz.</p>

          </motion.div>
        </div>
      </motion.div>




      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <div className='  w-full flex flex-col justify-center lg:pl-[10%] lg:pr-[10%] lg:py-[10%]  p-[10%]'>
          <motion.h5 variants={itemAnim1} className='text-4xl font-bold  mb-8  text-white '>SABİT KIYMET VE MALZEME POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim1} className='text-customGray leading-6 text-white space-y-2'>
<p>Firma olarak fabrika, ofis ve aldığımız tüm makinalarımızı hem fonksiyonelite hem de
estetik kaygılar güderek tedarik ediyoruz. Günümüz şartlarına uygun, estetik ve bakımlı
olmalarına özen gösteriyoruz. Her sabit kıymet ve makina yatırımımızda yarınları
düşünerek hareket ediyor, uzun vadeli düşünüyoruz. </p>
          </motion.div>
        </div>
        <motion.div variants={itemAnim2} className=' bg-red-400'>
          <img  className='h-full w-full object-cover aspect-square' src={'/images/yonetim-politikalari/sabit.jpg'} />
        </motion.div>
      </motion.div>


      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <motion.div variants={itemAnim1} className=' bg-orange-300 w-full order-2 lg:order-1'>
          <img  className='h-full w-full object-cover aspect-square' src={'/images/yonetim-politikalari/halal.jpg'} />
        </motion.div>
        <div className='  w-full flex flex-col justify-center lg:pr-[10%] lg:pl-[10%] lg:py-[10%]  p-[10%] order-1 lg:order-2'>
          <motion.h5 variants={itemAnim2} className='text-4xl font-bold  mb-8 text-white '>HELAL POLİTİKASI</motion.h5>
          <motion.div variants={itemAnim2} className='text-customGray leading-6 text-white space-y-2'>

<p >Her çeşitli ekmeklik ve özel amaçlı ürünlerimizin üretimi ve müşterilerimize sunulması
aşamasında istenilen spektlere ve helal yönetim sistemi gerekliliklerine uygun şekilde
hizmet sağlamaktayız. </p>
<p>Ürün spektlerinin geliştirilmesi ve müşteriye uygun olacak şekilde dizayn edilmesi
aşamalarında Gıda Güvenliği ekibimiz daima Türk Gıda Kodeksi ve Helal Prosedür ve
Standarları’na uygunluğunu esas alır. </p>
<p>Satın alma, laboratuvar ve üretim birimlerimiz birlikte çalışarak bu konudaki riskleri daima
minimize etmektedir. 
</p>
          </motion.div>
        </div>
      </motion.div>



    </div>


    </div>
















                <div className=' w-full h-96   px-8'>
                    <div className='grid  grid-cols-1 lg:grid-cols-2 w-full'>
                    {
                        certificateData.map((item, index) => {
                            return (
                         <a href={item.link} className='inline-block py-3'>
                                <motion.div key={index} variants={itemText}  className={` group hover:scale-105  transition-transform  duration-500 flex  justify-start lg:justify-end  items-center  ${index % 2 === 0 ? 'flex-row mr-5' : 'lg:flex-row-reverse lg:ml-5 '}`}>
                                <img className='h-5 w-5 block lg:hidden mr-3' src='/images/politikalar/icon.svg'/>
                                    <div className='    overflow-hidden cursor-pointer'>

                                        <h5 className={`text-white  ${index % 2 === 0 ? ' mr-3' : 'lg:ml-3'}`}>{item.text}</h5>
                                    </div>

                                    <img className='h-5 w-5 hidden lg:block' src='/images/politikalar/icon.svg'/>
                                </motion.div>
               
                                </a>

                            )
                        })
                    }
                    </div>






                </div>
            </div>


        </motion.div>
    )
}

export default YönetimPolitikalarimiz