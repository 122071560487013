import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion, useTransform, useViewportScroll } from "framer-motion"


const DATA = [
  {
    title: 'Anasayfa',
    link: '/',

  },
  {
    title: 'Kurumsal',
    link: '/kurumsal/tarihce',
    dropDown: [
      {
        title: 'Tarihçe',
        link: '/kurumsal/tarihce'
      },
      {
        title: 'Vizyon&Misyon',
        link: '/kurumsal/vizyon-misyon'
      },
      {
        title: 'Kalite Yönetimi',
        link: '/kurumsal/kalite-yonetimi'
      },
      {
        title: 'Yönetim Politikalarımız',
        link: '/kurumsal/yonetim-politikalarimiz'
      },

    ]

  },
  {
    title: 'İnovasyon',
    link: '/inovasyon/innovasyon-merkezi',
    dropDown: [
      {
        title: 'Innovasyon Merkezi',
        link: '/inovasyon/innovasyon-merkezi'
      },

      {
        title: 'Baking Square',
        link: '/inovasyon/baking-square',

      }
    ]
  },

  {
    title: 'Ürünlerimiz',
    link: '/urunler',
    dropDown: [
      {
        title: 'Tüm Ürünler',
        link: '/urunler',
        // icon: '/images/menu/5.svg'
      },
      {
        title: 'Fırın Serisi',
        link: '/urunler/firin-serisi',
        icon: '/images/menu/5.svg'
      },
      {
        title: 'Pastane Serisi',
        link: '/urunler/pastane-serisi',
        icon: '/images/menu/1.svg'

      },
      {
        title: 'Usta Serisi',
        link: '/urunler/usta-serisi',
        icon: '/images/menu/3.svg'

      },
      {
        title: 'Yöresel Seri',
        link: '/urunler/yoresel-serisi',
        icon: '/images/menu/2.svg'

      },
      {
        title: 'Lüks Seri',
        link: '/urunler/luks-serisi',
        icon: '/images/menu/4.svg'

      },


      {
        title: 'Lifli Seri',
        link: '/urunler/lifli-seri',
        icon: '/images/menu/a.svg'

      },


     
      // {
      //   title:'Ürün Talep Formu',
      //   link:'urun-talep-formu',

      // }
    ]
  },

  {
    title: 'Kariyer',
    link: '/kariyer/insankaynaklari-politikalarimiz',
    dropDown: [
      {
        title: 'İnsan Kaynakları Politikalarımız',
        link: '/kariyer/insankaynaklari-politikalarimiz'
      },
      {
        title: 'İş İlanları',
        link: '/kariyer/is-ilanlari',

      },
    ]
  },




]

const Header = ({ menuType, setMenuType, headerTransparent }) => {
  const headerContainer = useRef()


  const location = useLocation();
  const { scrollY } = useViewportScroll();
  const bgOpacity = useTransform(scrollY, [0, 2 * 16], [0, 1]);
  const bgColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 1)"]
  );


  const icon2Height = useTransform(
    scrollY,
    [0, 2 * 16],
    ['10rem', '5rem']
  );


  const icon3Height = useTransform(
    scrollY,
    [0, 2 * 16],
    ['5rem', '4rem']
  );

  const icon2Opacity = useTransform(
    scrollY,
    [0, 2 * 16],
    [1, 0]
  );







  useGSAP(

    () => {

      gsap.from(".line1", {

        opacity: 0,
        duration: 1,
        delay: 1,
        y: 16



      });
      gsap.from(".line2", {

        x: 400,
        duration: 1

      });
      gsap.from(".line3", {

        opacity: 0,
        duration: 1,
        delay: 1,
        y: -16

      });

      gsap.from(".logo", {

        scale: 0.9,
        opacity: 0,
        duration: 2,
        x: 20,



      });


      gsap.from(".li-1", {

        opacity: 0,
        duration: 2,
        delay: 0.5


      });

      gsap.from(".li-2", {

        opacity: 0,
        duration: 3,
        delay: 1



      });

      gsap.from(".li-3", {

        opacity: 0,
        duration: 4,
        delay: 1.5


      });


      // gsap.from(".logo-degirmen", {

      //   scale:0.9,
      //   opacity:0,
      //   duration:2,
      //   x:20,



      // });


      gsap.from(".logo-degirmen", {

        scale: 0.95,

        opacity: 0.8,
        repeat: -1,
        yoyo: 1,
        duration: 1



      });



    }, [headerContainer]
  )

  const backgroundHover = (menuType || headerTransparent) ? 'hover:bg-black' : 'hover:bg-white'


  const variants = {
    visible: index => ({
      opacity: [0, 0, 0, 1],
      translateX: 0,
      transition: {
        duration: (index + 1) * 0.4,
      },
    }),
    hidden: { opacity: 0, translateX: -100 },
  }




  return (
    <motion.header style={{ backgroundColor: bgColor }} ref={headerContainer} className={`h-28 lg:h-28 transition-all  duration-500  w-full px-8 md:px-16 lg:px-36 fixed z-30    ${(menuType || headerTransparent) ? 'bg-transparent' : 'bg-black'}`}>
      <div className="w-full  mx-auto h-full  flex items-center justify-between relative">
        <Link to={'/'}>
          <img
            src="/images/logos/logo.png"
            className="h-14 md:h-16 cursor-pointer logo z-20 relative"
            alt="logo"

          />
        </Link>
        <div className='flex justify-center items-center space-x-2 md:space-x-12 h-full'>
          {/* <div className=" items-center h-full space-x-12 hidden lg:flex">
            <Link to={'history'}>
              <h6 className="font-bold text-sm text-white cursor-pointer li-1  hover:scale-110">
                Kurumsal
              </h6>
            </Link>
            <Link to={'home37'}><h6 className="font-bold text-sm text-white cursor-pointer li-2">Faaliyet Alanları</h6></Link>
            <Link to={'home38'}><h6 className="font-bold text-sm text-white cursor-pointer li-3">Ürünler</h6></Link>
            <Link to={'insankaynaklari'}><h6 className="font-bold text-sm text-white cursor-pointer li-3">İnsan Kaynakları</h6></Link>
          </div> */}
          <nav className=" items-center h-20 md:h-28 hidden lg:flex">
            <ul className='flex  h-full space-x-8'>
              {
                DATA.map((item, index) => {
                  return (
                    <li key={index} className={`group cursor-pointer relative flex  items-center c    transition-all duration-500   `}>
                      <Link to={item.link} className='h-full'>
                        <motion.h6 key={index} className={`${item.link === location.pathname ? '!text-inceoglunYellow1' : 'text-white'} h-full transition-all duration-500     group-hover:!text-tedakblue group-hover:!text-inceoglunYellow1  flex flex-col justify-between  `}>
                          <div className=''></div>
                          <motion.span className='montSerrat' custom={index} animate="visible" initial="hidden" variants={variants}>{item.title}</motion.span>
                          <div className='h-px w-0 bg-white group-hover:bg-inceoglunYellow1 group-hover:w-full transition-all duration-500'></div>
                        </motion.h6>
                      </Link>
                      {
                        item.dropDown &&
                        <ul className='absolute opacity-0 top-28 left-0 invisible group-hover:visible  bg-black    shadow-lg text-white group-hover:opacity-100  overflow-hidden   transition-all duration-500 w-max'>
                          {
                            item.dropDown.map((item, index) => {
                              return (
                                <li key={index} className='group/innerDropDown '>
                                  <Link className='flex items-center' to={item.link}>{item.icon && <img className='h-6 w-6 ml-2' src={item.icon} />}
                                    <motion.h6 className={` px-4 py-2 flex group-hover/innerDropDown:!text-inceoglunYellow1  transition-all duration-500`}> {item.title}{item.dropDown && (<img className='ml-2 transform group-hover/innerDropDown:rotate-90 transition-all duration-500' src='/image/header/miniHook.svg' />)}</motion.h6></Link>
                                  <div className='h-px w-0 bg-white group-hover/innerDropDown:bg-inceoglunYellow1 group-hover/innerDropDown:w-full transition-all duration-500'></div>
                                  {
                                    item.dropDown &&
                                    <ul className='h-0 group-hover/innerDropDown:h-auto bg-transparent  overflow-hidden transition-all duration-500  '>
                                      {
                                        item.dropDown.map((item, index) => {
                                          return (
                                            <Link to={item.link}><li key={index} className='hover:text-tedakblue'>
                                              <motion.h6 className='  pl-5 '> {item.title}</motion.h6>
                                            </li>
                                            </Link>
                                          )
                                        })
                                      }
                                    </ul>
                                  }

                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </nav>

          <div className='h-full py-4 logo-degirmen relative -z-10 '>
            <motion.div className='flex items-center h-full lg:block' animate={{ rotate: 0, opacity: 1, transition: { duration: 1 } }} initial={{ rotate: -20, opacity: 0 }}>
              <motion.img
                src="/images/logos/logo2.png"
                style={{ height: icon2Height }}
                className='transition-all duration-1000  hidden lg:block'
                alt="logo"
              />

              <motion.img
                src="/images/logos/logo2.png"
                style={{ height: icon3Height }}
                className='transition-all duration-1000  block lg:hidden'
                alt="logo"
              />
            </motion.div>
          </div>


          {/* <div onClick={() => setMenuType(true)} className={`h-6 md:h-8 w-10  group   flex flex-col justify-between cursor-pointer ${menuType ? 'invisible' : 'visible'}  `} >
            <div className='line1  w-full h-[0.125rem]  bg-white '></div>
            <div className='line2  w-full h-[0.125rem] bg-white'></div>
            <div className='line3  w-full h-[0.125rem] bg-white'></div>
          </div> */}

          <motion.div onClick={() => setMenuType(!menuType)} className={`h-8  w-10 relative  group   flex flex-col justify-between cursor-pointer   `} >
            
            <motion.div animate={{ translateX: 0, opacity: 1, transition: { duration: 1 } }} initial={{ translateX: -100 }} className='flex space-x-2 justify-end lg:justify-start'>
              <div className={`absolute  w-10  h-px bg-white  transition-all duration-500 group-hover:bg-inceoglunYellow1 ${menuType ? 'w-10' : 'w-10'} ${menuType ? 'top-4' : 'top-0'} transform ${menuType ? 'rotate-[45deg]' : 'rotate-[0deg]'}`}></div>
            </motion.div>

            <motion.div animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }} initial={{ opacity: 0 }} className='flex justify-end w-full'>
              <div className={`absolute h-px bg-white transition-all duration-700 group-hover:bg-inceoglunYellow1 group-hover:w-10  ${menuType ? 'w-10' : 'w-4'} ${menuType ? 'top-4' : 'top-4'} ${menuType ? 'translate-x-96' : 'translate-x-[0]'}`}></div>
            </motion.div>

            <motion.div animate={{ translateX: 0, opacity: 1, transition: { duration: 1 } }} initial={{ translateX: 100, opacity: 0 }} className='flex space-x-2 justify-end'>
              <div className={`absolute h-px bg-white transition-all duration-1000 group-hover:bg-inceoglunYellow1 group-hover:w-10  ${menuType ? 'w-10' : 'w-7'} ${menuType ? 'bottom-4' : 'bottom-0'} ${menuType ? '-rotate-[45deg]' : 'rotate-[0deg]'}`}></div>
            </motion.div>

          </motion.div>
        </div>

      </div>

      <motion.div animate={{ width: '100%', transition: { duration: 1, ease: 'easeInOut' } }} initial={{ width: '0%' }} className='h-[0.6px] w-full bg-inceoglunYellow1 absolute bottom-0 left-0 -z-20'></motion.div>

    </motion.header>
  )
}

export default Header