import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'

const Menu1 = ({ menuType, setMenuType }) => {

    const variants = {
        visible: index => ({
            opacity: 1,
            transition: {
                delay: (index + 1) * 0.2,

            },
        }),
        hidden: { opacity: 0 },

    }


    const DATA = [
        {
            title: 'Anasayfa',
            link: '/',
            clickable:true

        },
        {
            title: 'Kurumsal',
            clickable:false,
            dropDown: [
                {
                    title: 'Tarihçe',
                    link: '/kurumsal/tarihce'
                },
                {
                    title: 'Vizyon&Misyon',
                    link: '/kurumsal/vizyon-misyon'
                },
                {
                    title: 'Kalite Yönetimi',
                    link: '/kurumsal/kalite-yonetimi'
                },
                {
                    title: 'Yönetim Politikalarımız',
                    link: '/kurumsal/yonetim-politikalarimiz'
                },

            ]

        },
        {
            title: 'İnovasyon',
            clickable:false,
            dropDown: [
                {
                    title: 'Innovasyon Merkezi',
                    link: '/inovasyon/innovasyon-merkezi'
                },
  

                {
                    title: 'Baking Square',
                    link: '/inovasyon/baking-square',

                }
            ]
        },



        {
            title: 'Kariyer',
            clickable:false,
            dropDown: [
                {
                    title: 'İnsan Kaynakları Politikalarımız',
                    link: '/kariyer/insankaynaklari-politikalarimiz'
                },
                {
                    title: 'İş İlanları',
                    link: '/kariyer/is-ilanlari',

                },
            ]
        },

        {
            title: 'Profesyonellerin Yanında',
            link: '/profesyonellerin-yaninda',
            clickable:true

        },

        {
            title: 'Gıda Güvenliği Sistemi',
            link: 'gida-guvenligi-sistemi',
            clickable:true

        },
        {
            title: 'Buğday Çeşitleri',
            link: 'bugday',
            clickable:true

        },
        {
            title: 'İletişim',
            link: 'Iletisim',
            clickable:true

        },




    ]


    const data1 = [
        {
            name: 'Innovasyon Merkezi',
            link: '/inovasyon/innovasyon-merkezi',
        },
        {
            name: 'Profesyonellerin Yanında',
            link: '/profesyonellerin-yaninda',
        },

        {
            name: 'Gıda Güvenliği Sistemi',
            link: 'gida-guvenligi-sistemi',
        },
        {
            name: 'Buğday Çeşitleri',
            link: '/bugday',
        },
        {
            name: 'İletişim',
            link: '/Iletisim',
        },
    ]


    const data2 = [
        {
            name: 'Tüm Ürünler',
            // imageUrl: '/images/menu/5.svg',
            link: '/urunler',
        },
        {
            name: 'Fırın Serisi',
            imageUrl: '/images/menu/5.svg',
            link: '/urunler/firin-serisi',
        },
        {
            name: 'Pastane Serisi',
            imageUrl: '/images/menu/1.svg',
            link: '/urunler/pastane-serisi',
        },
        {
            name: 'Usta Serisi',
            imageUrl: '/images/menu/3.svg',
            link: '/urunler/usta-serisi',
        },
        {
            name: 'Yöresel Seri',
            imageUrl: '/images/menu/2.svg',
            link: '/urunler/yoresel-serisi',
        },
        {
            name: 'Lüks Seri',
            imageUrl: '/images/menu/4.svg',
            link: '/urunler/luks-serisi',
        }
        ,
        {
            name: 'Lifli Seri',
            imageUrl: '/images/menu/a.svg',
            link: '/urunler/lifli-seri',
        }
        ,
        {
            name: 'Markaya Özel Reçeteli Ürünler',
            imageUrl: '/images/menu/b.svg',
            link: '/markaya-ozel-receteli-urunler',
        }
        ,
        {
            name: 'Özel Miksli Ürünler​',
            imageUrl: '/images/menu/d.svg',
    
            link: '/guncelleniyor',
        }
        ,
        {
            name: 'Çiftlik Serisi​',
            imageUrl: '/images/menu/c.svg',
            link: '/guncelleniyor',
        }
    ]

    return (

        <motion.div

            data-lenis-prevent="true"
            className={`h-screen relative w-full  `}>

            <div className={` overflow-y-auto  px-8 md:px-16 lg:px-36 h-[calc(100vh-5rem)] md:h-[calc(100vh-7rem)]    top-[5rem] md:top-[7rem]  w-full relative   z-40  grid grid-cols-1  md:grid-cols-3 py-20 gap-10 lg:gap-20`}>
                <motion.div className='h-full   flex-col space-y-6 text-xl '>
                    <>
                        <h5 className='text-gray-500'>İnceoğlu Dünyası</h5>
                        <div className='hidden lg:flex flex-col space-y-6 text-xl '>
                            {
                                data1.map((item, index) => {
                                    return (
                                        <Link to={item.link} key={index}>
                                            <motion.h5 custom={index}
                                                animate="visible" initial={'hidden'}
                                                variants={variants} className=' text-white font-bold hover:scale-105 transition-all duration-500 hover:text-inceoglunYellow1 text-lg montSerrat'>{item.name}</motion.h5>
                                        </Link>
                                    )

                                })
                            }
                        </div>
                        {






                            <nav className=" items-center  flex lg:hidden">
                                <ul className='flex  h-full  flex-col w-full'>
                                    
                                    {
                                        DATA.map((item, index) => {
                                            return (
                                                <li key={index} className={`group cursor-pointer relative flex flex-col c    transition-all duration-500 `}>
                                                    <Link to={item.clickable?item.link:''} onClick={item.clickable ?'' :(e)=>e.preventDefault()} className='h-full flex justify-between' >
                                                        <motion.h6 key={index} className={`py-2 h-full transition-all duration-500 text-white   group-hover:!text-tedakblue group-hover:!text-inceoglunYellow1  flex flex-col justify-between font-bold  `}>
                                                            <div className=''></div>
                                                            <motion.span custom={index} animate="visible" initial="hidden" variants={variants} className='text-lg'>{item.title}</motion.span>
                                                            <div className='h-px w-0 bg-white group-hover:bg-inceoglunYellow1 group-hover:w-full transition-all duration-500'></div>
                                                        </motion.h6>
                                                        <img className={`${item.clickable?'hidden':'block'} group-hover:rotate-90   transition duration-500`} src='/images/arrow.svg'/>
                                                    </Link>
                                                    {
                                                        item.dropDown &&
                                                        <ul className=' h-0 invisible group-hover:visible    text-white group-hover:h-auto  overflow-hidden   transition-all duration-500 '>
                                                            {
                                                                item.dropDown.map((item, index) => {
                                                                    return (
                                                                        <li key={index} className='group/innerDropDown '>
                                                                            <Link className='flex items-center' to={item.link}>{item.icon && <img className='h-6 w-6 ml-2' src={item.icon} />}
                                                                                <motion.h6 className={` px-4 py-2 flex group-hover/innerDropDown:!text-inceoglunYellow1  transition-all duration-500`}> {item.title}{item.dropDown && (<img className='ml-2 transform group-hover/innerDropDown:rotate-90 transition-all duration-500' src='/image/header/miniHook.svg' />)}</motion.h6></Link>
                                                                            <div className='h-px w-0 bg-white group-hover/innerDropDown:bg-inceoglunYellow1 group-hover/innerDropDown:w-full transition-all duration-500'></div>
                                                                            {
                                                                                item.dropDown &&
                                                                                <ul className='h-0 group-hover/innerDropDown:h-auto bg-transparent  overflow-hidden transition-all duration-500  '>
                                                                                    {
                                                                                        item.dropDown.map((item, index) => {
                                                                                            return (
                                                                                                <Link to={item.link}><li key={index} className='hover:text-tedakblue'>
                                                                                                    <motion.h6 className='  pl-5 '> {item.title}</motion.h6>
                                                                                                </li>
                                                                                                </Link>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            }

                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </nav>

                        }

                    </>
                </motion.div>
                <motion.div className='h-full  '>
                    <>

                        <h5 className='text-gray-500 text-xl'>Bizim Ürünlerimiz</h5>
                        {
                            data2.map((item, index) => {
                                return (
                                    <Link to={item.link}>
                                        <motion.div custom={index}
                                            animate="visible" initial={'hidden'}
                                            variants={variants} key={index} className=' text-white   flex items-center space-x-5 border-white border-b  py-4 hover:scale-105 transition-all duration-500 hover:text-inceoglunYellow1'>
                                            <div className='h-8 w-8  flex justify-center items-center '>
                                                <img className='h-full' src={item.imageUrl} />
                                            </div>
                                            <h5 className='font-bold text-xl '>{item.name}</h5>
                                        </motion.div>
                                    </Link>
                                )

                            })
                        }
                    </>

                </motion.div>
                {/* <div className=' flex justify-end  w-full' >
                    <div className='h-10 w-10 flex justify-center items-center relative cursor-pointer ' onClick={() => { setMenuType(false) }}>
                        <motion.div
                            className='h-[0.12rem] w-full absolute transform rotate-45 bg-[#707070] cross-1'>

                        </motion.div>
                        <div className='h-[0.12rem] w-full absolute transform -rotate-45 bg-[#707070] cross-2'></div>
                    </div>
                </div> */}


            </div>
            <motion.div key="modal" initial={{ opacity: 0, width: '0%' }}
                animate={{ opacity: 1, width: '100%', transition: { duration: 1.5, delay: 1 } }}
                exit={{ opacity: 0, width: '0%', transition: { duration: 1 } }} className='h-[0.6px] w-full bg-inceoglunYellow1 absolute bottom-[1px] left-0 z-40'>

            </motion.div>

            <div className='absolute  h-screen  top-0 left-0  overflow-hidden'>
                <motion.img key="modal" initial={{ opacity: 0, scale: 1.5 }} className=' min-h-screen object-cover w-full'
                    animate={{ opacity: 1, scale: 1, transition: { duration: 2, delay: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }} src={'/images/menu/menu-banner.jpg'} />
            </div>
        </motion.div>


    )
}

export default Menu1