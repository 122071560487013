import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';


ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const MarkayaOzelReceteliUrunler = () => {

  const data = {
    labels: ['Protein','Kül','Gluten','Gluten Indeks','Sedimantasyon','G. Sedimantasyon','Uzayabilirlik', 'Direnç', 'Enerji', 'Su Kaldırma', 'Gelişme Süresi', 'Stabilite', 'Nişasta', 'Jeletinizasyon Maksimumu', 'Jeletinizasyon Sıcaklığı', 'Düşme Sayısı', 'Elek Analizi'],
    datasets: [
      {
        label: '',
        data: [70,50,90,65,80,90 ,80, 80, 80, 85, 50, 95, 55, 70, 65, 70, 80],
        backgroundColor: 'rgba(0, 38, 135,0.8)',
        borderColor: '#007be0',
        borderWidth: 2,
        

      


        
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          // color: 'green',
          // font: {
          //   size: 14,
          // },

          display: false,
        },
        pointLabels: {
          color: '#fff', // Set the label color around the chart to green
          font: {
            
            size: 16, // Increase the font size of the labels around the chart
          },
          
        },
        grid: {
          color: '#fff', // Set the grid lines inside the radar to white
        },
      },
    },
  };

  const [markerPositions, setMarkerPositions] = useState({
    protein: 0,
    kul: 0,
    gluten: 0,
    glutenIndeks: 0,
    sedimantasyon: 0,
    gSedimantasyon: 0,
    uzayabilirlik: 0,
    direnc: 0,
    enerji: 0,
    suKaldirma: 0,
    gelismeSuresi: 0,
    stabilite: 0,
    nisasta: 0,
    jeletinizasyonMax: 0,
    jeletinizasyonSicaklik: 0,
    dusmeSayisi: 0,
    elekAnalizi: 0,
  });

  // Animation
  useEffect(() => {
    setTimeout(() => {
      setMarkerPositions({
        protein: '70%',
        kul: '8%',
        gluten: '90%',
        glutenIndeks: '65%',
        sedimantasyon: '80%',
        gSedimantasyon: '70%',
        uzayabilirlik: '60%',
        direnc: '80%',
        enerji: '80%',
        suKaldirma: '85%',
        gelismeSuresi: '25%',
        stabilite: '95%',
        nisasta: '55%',
        jeletinizasyonMax: '70%',
        jeletinizasyonSicaklik: '65%',
        dusmeSayisi: '60%',
        elekAnalizi: '70%',
      });
    }, 100); // Small delay to start animation
  }, []);

  const rowVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: (custom) => ({
      opacity: 1,
      x: 0,
      transition: { delay: custom * 0.2, duration: 0.8 },
    }),
  };

  const renderRow = (label, key, index) => (
    <motion.div
      className=' w-full pt-5 grid grid-cols-5  '
      initial="hidden"
      animate="visible"
      custom={index}
      variants={rowVariants}
      key={index}
    >
      <motion.div className=' col-span-3 md:col-span-2 text-white  text-sm md:text-xl'>{label}</motion.div>
      <motion.div className=' col-span-2 md:col-span-3 relative content-center'>
        <div className='w-full h-5 customGradient-2 '></div>
        <motion.div
          className='h-4 w-4 customGradient-3 absolute top-px md:top-1.5 rotate-45'
          initial={{ left: '0%' }}
          animate={{ left: markerPositions[key] }}
          transition={{ duration: 1.5, ease: 'easeInOut', delay: index * 0.2 }}
        ></motion.div>
      </motion.div>
    </motion.div>
  );

  return (
    <div
      className='min-h-screen w-full px-8 md:px-16 lg:px-36 py-20 md:py-32'
      style={{
        background: 'rgb(69,40,0)',
        background: 'radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)',
      }}
    >
      {/* <div className='relative flex justify-center md:space-x-10 flex-col md:flex-row space-y-16 md:space-y-0'>
        <div className='w-full md:w-2/5  relative'>
          {renderRow('Protein', 'protein', 0)}
          {renderRow('Kül', 'kul', 1)}
          {renderRow('Gluten', 'gluten', 2)}
          {renderRow('Gluten Indeks', 'glutenIndeks', 3)}
          {renderRow('Sedimantasyon', 'sedimantasyon', 4)}
          {renderRow('G. Sedimantasyon', 'gSedimantasyon', 5)}
          {renderRow('Uzayabilirlik', 'uzayabilirlik', 6)}
          {renderRow('Direnç', 'direnc', 7)}
          {renderRow('Enerji', 'enerji', 8)}
          {renderRow('Su Kaldırma', 'suKaldirma', 9)}
          {renderRow('Gelişme Süresi', 'gelismeSuresi', 10)}
          {renderRow('Stabilite', 'stabilite', 11)}
          {renderRow('Nişasta Zedenlenmesi', 'nisasta', 12)}
          {renderRow('Jeletinizasyon Maksimumu', 'jeletinizasyonMax', 13)}
          {renderRow('Jeletinizasyon Sıcaklığı', 'jeletinizasyonSicaklik', 14)}
          {renderRow('Düşme Sayısı', 'dusmeSayisi', 15)}
          {renderRow('Elek Analizi', 'elekAnalizi', 16)} 
        </div>

      </div> */}







      <Radar data={data} options={options} />

      <div className='w-full flex flex-col  space-y-14 '>


        <div className='text-gray-300 flex flex-col font-light items-center'>
          <p className='mb-6'>Markalara özel, istenilen spekt değerlerinde ürünler
            üretebiliyoruz.</p>
          <p className='mb-6'>Spekti olmayan potansiyel müşterilerimizin ürün
            proseslerini öğreniyoruz ve özel paçal ve reçeteler
            ile ürünlerimizi tüketimlerine sunuyoruz.
          </p>
          <p className='mb-6'>Sırasıyla çeşitli aşamalardan geçiyoruz;</p>
          <p className=''>Demo Üretim Denemesi</p>
          <p className=''>Seri Üretim Denemesi</p>
          <p className=''>Ürün Düzeltmeleri</p>
          <p className=''>Son Ürün Validasyonu</p>
        </div>
      </div>

      <div className='w-full   mt-36'>
        <video preload="metadata" autoPlay loop muted className=' object-cover relative z-20  w-full'>
          <source src="/videos/2.mp4" type="video/mp4" className='w-full' />
        </video>
      </div>

    </div>
  );
};

export default MarkayaOzelReceteliUrunler;
